.home{
    display: flex;
    background-color: #f9f9f9;
    font-family: 'Poppins', sans-serif;
}
.home1{
    display: flex;
    margin-left: -189px;
    margin-top: 50px;
    background-color: #f9f9f9;
}
.home2{
    display: flex;
    background-color: #f9f9f9;
}