.sidebarItem {
    flex: 4;
    display: flex;
    margin-right: 5rem;
    padding-right: 0;
    margin-left: 12rem;
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius:5px;
    margin-bottom: 2px;
}
.heading {
    text-align: center;
    height: 40px;
    width: 500px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background-color: #1ababb;
    color: white;
    padding-top: 2px;
}
.scrollText{
    position: relative;
    top: 50px;
    left: 0;
    height: calc(100% - 50px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    animation: marquee 10s linear infinite;
    background-color: transparent;
    margin-left: 1px;
    justify-items:center;
    z-index: 1;
}
@keyframes marquee {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(-100%);
    }
}
.cardshadow {
    box-shadow: 0 4px 20px  rgba(0, 0, 0, 0.4);
}