.container {
    width: 100%;
    background-color: #1976d2;
    margin-top:2px;
}

.announcementText {
    display: inline-block;
    margin-left:5px;
    margin-right:5px;
    white-space: nowrap;
    color:white;
    text-align:center;/* Set the desired text color */
}
.blink{
    margin-left: 15px;
    margin-right: 10px;

}