.rightbar{
    display: flex;
    flex: 4;
    margin-top: -3px;
    margin-left: -10rem;
    justify-content: space-between;
    text-align: justify;
    margin-right: 5px;
}
.missionHeading2 {
    color: #f9f9f9;
    font-weight:700;
}
.infoText2 {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    padding-right:30px;
    line-height:30px;
    font-size:15px;
}
.link2 {
    font-family: Poppins sans-serif;
    text-decoration: none;
    font-size: larger;
    color: #f9f9f9;
    overflow: hidden;
    -webkit-line-clamp: 8;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-bottom: 40px;
}
.cardshadow2{
    box-shadow: 0 4px 20px  rgba(0, 0, 0, 0.4);
}