.middlebar {
    display: flex;
    flex: 4;
    justify-content: space-between;
    text-align :justify;
    padding-right: 200px;
    background-color: #f9f9f9;
    font-family :Poppins sans-serif;
}
.aboutHeading1 {
    color: #f9f9f9;
    font-weight:700;
}
.infoText1 {
    margin-right: 10px;
    justify-content: center;
    flex-wrap: wrap;
    font-family: Poppins sans-serif;
    line-height:1.5 ;
}
.link1 {
    font-family :Poppins sans-serif;
    text-decoration: none;
    font-size: larger;
    color :#f9f9f9;
    overflow: hidden;
    -webkit-line-clamp: 8;
    text-overflow:ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-bottom :40px;
}
.cardshadow1 {
    box-shadow :0 4px 20px  rgba(0, 0, 0, 0.4);
}