.root {
    flex-grow: 1;
    text-align: center;
    margin-top: 10px;
}
.paper {
    padding: 10px;
    color: white;
}
.image {
    width: 100%;
    height: 500px;
    max-height: 600px;
}
.buttonContainer {
    margin-top: 2px;
}
.button {
    margin-left:2px;
}